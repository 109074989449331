import { useEffect, useState } from "react";
import styled from "styled-components";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { Container, Snackbar, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Header } from "./Header";
import { AlertState, getAtaForMint } from "./utils";
import { MintButton } from "./MintButton";
import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  CANDY_MACHINE_PROGRAM,
} from "./candy-machine";

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_DECIMALS
  ? +process.env.REACT_APP_SPL_TOKEN_DECIMALS!.toString()
  : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_NAME
  ? process.env.REACT_APP_SPL_TOKEN_NAME.toString()
  : "TOKEN";

const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--card-background-lighter-color) !important;
  margin: 5px;
  padding: 24px;
`;

const WalletAmount = styled.div`
  color: black;
  width: auto;
  height: 80%;
  padding: 0 0px 0 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 24px;
  background-color: var(--main-text-color);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  font-size: 0.7em;
  text-transform: uppercase;
  border: 0;
  margin: auto;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  gap: 10px;
`;

const ConnectButton = styled(WalletMultiButton)`
  margin: auto 0;
  width: 100%;
  height: 80%;
  color: white;
  font-size: 1em;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

const MintContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 20px;
  background: linear-gradient(180deg, #12121e 0%, #2b2b3d 100%);
`;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
  const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [payWithSplToken, setPayWithSplToken] = useState(false);
  const [price, setPrice] = useState(0);
  const [priceLabel, setPriceLabel] = useState<string>("SOL");
  const [whitelistPrice, setWhitelistPrice] = useState(0);
  const [whitelistEnabled, setWhitelistEnabled] = useState(false);
  const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const rpcUrl = props.rpcHost;

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const cndy = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setCandyMachine(cndy);
      setItemsAvailable(cndy.state.itemsAvailable);
      setItemsRemaining(cndy.state.itemsRemaining);
      setItemsRedeemed(cndy.state.itemsRedeemed);

      var divider = 1;
      if (decimals) {
        divider = +("1" + new Array(decimals).join("0").slice() + "0");
      }

      // detect if using spl-token to mint
      if (cndy.state.tokenMint) {
        setPayWithSplToken(true);
        // Customize your SPL-TOKEN Label HERE
        // TODO: get spl-token metadata name
        setPriceLabel(splTokenName);
        setPrice(cndy.state.price.toNumber() / divider);
        setWhitelistPrice(cndy.state.price.toNumber() / divider);
      } else {
        setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
        setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
      }

      // fetch whitelist token balance
      if (cndy.state.whitelistMintSettings) {
        setWhitelistEnabled(true);
        if (
          cndy.state.whitelistMintSettings.discountPrice !== null &&
          cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price
        ) {
          if (cndy.state.tokenMint) {
            setWhitelistPrice(
              cndy.state.whitelistMintSettings.discountPrice?.toNumber() /
                divider
            );
          } else {
            setWhitelistPrice(
              cndy.state.whitelistMintSettings.discountPrice?.toNumber() /
                LAMPORTS_PER_SOL
            );
          }
        }
        let balance = 0;
        try {
          const tokenBalance = await props.connection.getTokenAccountBalance(
            (
              await getAtaForMint(
                cndy.state.whitelistMintSettings.mint,
                wallet.publicKey
              )
            )[0]
          );

          balance = tokenBalance?.value?.uiAmount || 0;
        } catch (e) {
          console.error(e);
          balance = 0;
        }
        setWhitelistTokenBalance(balance);
        setIsActive(balance > 0);
      } else {
        setWhitelistEnabled(false);
      }
    })();
  };

  const renderCounter = ({ days, hours, minutes, seconds }: any) => {
    return (
      <div>
        <Card elevation={1}>
          <h1>{days}</h1>
          <br />
          Days
        </Card>
        <Card elevation={1}>
          <h1>{hours}</h1>
          <br />
          Hours
        </Card>
        <Card elevation={1}>
          <h1>{minutes}</h1>
          <br />
          Mins
        </Card>
        <Card elevation={1}>
          <h1>{seconds}</h1>
          <br />
          Secs
        </Card>
      </div>
    );
  };

  function displaySuccess(mintPublicKey: any): void {
    let remaining = itemsRemaining - 1;
    setItemsRemaining(remaining);
    setIsSoldOut(remaining === 0);
    if (whitelistTokenBalance && whitelistTokenBalance > 0) {
      let balance = whitelistTokenBalance - 1;
      setWhitelistTokenBalance(balance);
      setIsActive(balance > 0);
    }
    setItemsRedeemed(itemsRedeemed + 1);
    const solFeesEstimation = 0.012; // approx
    if (!payWithSplToken && balance && balance > 0) {
      setBalance(
        balance -
          (whitelistEnabled ? whitelistPrice : price) -
          solFeesEstimation
      );
    }
    setSolanaExplorerLink(
      cluster === "devnet" || cluster === "testnet"
        ? "https://explorer.solana.com/address/" +
            mintPublicKey +
            "?cluster=" +
            cluster
        : "https://explorer.solana.com/address/" + mintPublicKey
    );
    throwConfetti();
  }

  function throwConfetti(): void {
    confetti({
      particleCount: 400,
      spread: 70,
      origin: { y: 0.6 },
    });
  }

  const onMint = async () => {
    try {
      setIsMinting(true);
      document.getElementById("#identity")?.click();
      if (wallet && candyMachine?.program && wallet.publicKey) {
        const mint = anchor.web3.Keypair.generate();
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey, mint)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            "singleGossip",
            true
          );
        }

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });

          // update front-end amounts
          displaySuccess(mint.publicKey);
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction Timeout! Please try again.";
        } else if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  let url = "https://primatewarfare.club/";
  let twitter = "https://twitter.com/PrimateWarfare";
  let facebook =
    "https://www.facebook.com/Primate-Warfare-Club-101453802450280/";

  return (
    <>
      <main>
        <div className="bg-img"></div>

        <section id="navbar">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
              <a className="navbar-brand"> </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse nav-basis"
                id="navbarNav"
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item px-3">
                    <a
                      className="nav-link"
                      href={twitter}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i className="fab fa-twitter fa-lg"></i>
                    </a>
                  </li>
                  <li className="nav-item px-3">
                    <a className="nav-link" href="#">
                      <i className="fab fa-discord fa-lg"></i>
                    </a>
                  </li>
                  <li className="nav-item px-3">
                    <a className="nav-link" href="#">
                      <i className="fab fa-telegram fa-lg"></i>
                    </a>
                  </li>
                  <li className="nav-item px-3">
                    <a
                      className="nav-link"
                      href={facebook}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i className="fab fa-facebook fa-lg"></i>
                    </a>
                  </li>
                  <li className="nav-item px-3 align-items-center d-flex">
                    {wallet ? (
                      <WalletAmount>
                        {(balance || 0).toLocaleString() + " SOL"}
                        <ConnectButton />
                      </WalletAmount>
                    ) : (
                      <ConnectButton>Connect Wallet</ConnectButton>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
        <section id="banner">
          <div className="banner">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-12 align-items-center d-flex justify-content-center">
                  <a href={url} rel="noreferrer" target={"_blank"}>
                    <img src="logo-min.png" className="logo" alt="logo" />
                  </a>
                  <h3>PRIMATE WARFARE CLUB</h3>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-5 text-center">
                  <img
                    src="primate.gif"
                    alt=""
                    id="image"
                    style={{ width: "300px" }}
                  />
                </div>
                <div className="col-md-7 text-center my-auto align-items-center px-5">
                  <h4
                    style={{ fontWeight: "bold", padding: "20px" }}
                    className="align-items-start"
                  >
                    Mint Yours!
                  </h4>
                  <Container disableGutters>
                    <Container
                      disableGutters
                      maxWidth="md"
                      style={{ position: "relative" }}
                    >
                      <Paper>
                        {!wallet?.publicKey ? (
                          <ConnectButton style={{ borderRadius: "0" }}>
                            Connect Wallet
                          </ConnectButton>
                        ) : (
                          <>
                            <MintContainer
                              style={{
                                padding: 20,
                                backgroundColor: "#311e4e",
                              }}
                            >
                              <Header candyMachine={candyMachine} />
                              {candyMachine?.state.isActive &&
                              candyMachine?.state.gatekeeper &&
                              wallet.publicKey &&
                              wallet.signTransaction ? (
                                <GatewayProvider
                                  wallet={{
                                    publicKey:
                                      wallet.publicKey ||
                                      new PublicKey(CANDY_MACHINE_PROGRAM),
                                    //@ts-ignore
                                    signTransaction: wallet.signTransaction,
                                  }}
                                  gatekeeperNetwork={
                                    candyMachine?.state?.gatekeeper
                                      ?.gatekeeperNetwork
                                  }
                                  clusterUrl={rpcUrl}
                                  options={{ autoShowModal: false }}
                                >
                                  <MintButton
                                    candyMachine={candyMachine}
                                    isMinting={isMinting}
                                    isActive={isActive}
                                    isSoldOut={isSoldOut}
                                    onMint={onMint}
                                  />
                                </GatewayProvider>
                              ) : (
                                <MintButton
                                  candyMachine={candyMachine}
                                  isMinting={isMinting}
                                  isActive={isActive}
                                  isSoldOut={isSoldOut}
                                  onMint={onMint}
                                />
                              )}
                            </MintContainer>
                          </>
                        )}
                      </Paper>
                    </Container>

                    <Snackbar
                      open={alertState.open}
                      autoHideDuration={6000}
                      onClose={() =>
                        setAlertState({ ...alertState, open: false })
                      }
                    >
                      <Alert
                        onClose={() =>
                          setAlertState({ ...alertState, open: false })
                        }
                        severity={alertState.severity}
                      >
                        {alertState.message}
                      </Alert>
                    </Snackbar>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="text-white pt-5 pb-4" id="community">
          <div className="container text-md-left">
            <div className="row align-items-center">
              <div className="col-md-12 text-center col-lg-12">
                <p>
                  Copyright ©2022 All rights reserved by:
                  <a
                    href={url}
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <strong style={{ color: "#FED02A" }}>
                      Primate Warfare Club
                    </strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
};

export default Home;
